import {groupBy, map} from 'lodash';
import {Model, Collection} from './vue-mc/Model';
import {MatchVersus, MatchVersusTeam, MatchVersusParticipant} from './MatchVersus';

export class Match extends Model {
    /**
     * Default attributes that define the "empty" state.
     *
     * @return {object}
     */
    defaults() {
        return {
            id: null,
            holes_not_played: null,
            winning_score: null,
            winning_team: null,
        };
    }

    /**
     * Options of this model.
     *
     * @return {object}
     */
    options() {
        return {
            endpoint: 'matches',
        };
    }

    /**
     * Returns string representation of this match's score.
     * Examples: "AS", "2&1", or "2UP".
     *
     * @return {string}
     */
    get score() {
        if (this.winning_score === 0) {
            return 'AS';
        }

        if (this.winning_score === -1) {
            return 'WIN';
        }

        if (this.holes_not_played === 0) {
            return `${this.winning_score}UP`;
        }

        if (this.winning_score && this.holes_not_played) {
            return `${this.winning_score}&${this.holes_not_played}`;
        }

        return '';
    }

    /**
     * Sets the `winning_score` and `holes_not_played` from the given score string.
     * This method is the opposite of the score getter above.
     *
     * @param {string} score A golf match score, something like "AS", "2&1", or "2UP".
     */
    set score(score) {
        let winning_score, holes_not_played;

        if (score === 'AS') {
            winning_score = 0;
            holes_not_played = 0;
        }

        if (score === 'WIN') {
            winning_score = -1;
            holes_not_played = 0;
        }

        if (score.endsWith('UP')) {
            winning_score = parseInt(score[0]);
            holes_not_played = 0;
        }

        if (score.includes('&')) {
            [winning_score, holes_not_played] = score.split('&');
        }

        this.winning_score = winning_score;
        this.holes_not_played = holes_not_played;
    }

    /**
     * Transforms the Match to a MatchVersus instance.
     *
     * @return {MatchVersus}
     */
    toMatchVersus() {
        let teams = groupBy(this.users, 'pivot.team');

        // Since `teams` is an Object, we have to use `_.map`.
        teams = map(teams, (team, teamNumber) => {
            return new MatchVersusTeam(
                this.teamToMatchVersusParticipants(team),
                null,
                this.winning_team === parseInt(teamNumber),
            );
        });

        return new MatchVersus(teams);
    }

    /**
     * Transforms a team to an array of MatchVersusParticipant instance.
     *
     * @param {User[]} team
     * @return {MatchVersusParticipant[]}
     */
    teamToMatchVersusParticipants(team) {
        return team.map((user) => {
            return new MatchVersusParticipant(
                `${user.first_name} ${user.last_name}`,
                user.image[140],
            );
        });
    }

    /**
     * All possible scores of a golf match.
     *
     * @return {string[]}
     */
    static get possibleScores() {
        return [
            'AS',
            '1UP',
            '2UP',
            '2&1',
            '3&1',
            '3&2',
            '4&2',
            '4&3',
            '5&3',
            '5&4',
            '6&4',
            '6&5',
            '7&5',
            '7&6',
            '8&6',
            '8&7',
            '9&7',
            '9&8',
            '10&8',
        ];
    }
};

export class Matches extends Collection {
    /**
     * Options of this collection.
     *
     * @return {object}
     */
    options() {
        return {
            model: Match,
        };
    }
};
