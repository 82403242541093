import {Match} from './Match';
import {TeamUsers} from './TeamUser';
import {Model, Collection} from './vue-mc/Model';
import MatchType from '@/library/enums/MatchType';
import {MatchVersus, MatchVersusTeam} from './MatchVersus';
import TeamMatchScheduleStatus from '@/library/enums/TeamMatchScheduleStatus';

export class TeamMatchSchedule extends Model {
    /**
     * Default attributes that define the "empty" state.
     *
     * @return {object}
     */
    defaults() {
        return {
            id: null,
            match_id: null,
            match: {},
            match_type: null,
            team_users: [],
            status: null,
        };
    }

    /**
     * Options of this model.
     *
     * @return {object}
     */
    options() {
        return {
            endpoint: 'team-match-schedules',
        };
    }

    /**
     * Mutations that should be done to the attributes of this model.
     *
     * @return {object}
     */
    mutations() {
        return {
            match: (val) => new Match(val),
            match_type: (val) => new MatchType(val),
            team_users: (models) => models instanceof TeamUsers ?
                models : new TeamUsers(models),
        };
    }

    /**
     * Returns the max amount of users per team that may play in this schedule.
     *
     * @return {number|undefined}
     */
    get max_per_team() {
        return MatchType.userPerTeamAmountForType(this.match_type.value);
    }

    /**
     * Returns a boolean that indicates if the schedule's match has been reported
     * as incorrect.
     *
     * @return {boolean}
     */
    get match_is_incorrect() {
        return this.has_been_played
            && !this.match_id;
    }

    /**
     * Returns a boolean that indicates if the schedule's match has played.
     *
     * @return {boolean}
     */
    get has_been_played() {
        return this.status === TeamMatchScheduleStatus.PLAYED;
    }

    /**
     * Returns an array of `TeamUser` who play in this schedule and belong
     * to the given team.
     *
     * @param {number} teamId
     * @return {TeamUser[]}
     */
    getTeamUsersOfTeam(teamId) {
        return this.team_users.where(tu => tu.team_id === teamId);
    }

    /**
     * Transforms the TeamMatchSchedule to a MatchVersus instance. The TeamMatch
     * instance is needed to ensure that the schedule's team users are rendered
     * in their correct team side.
     *
     * @param  {TeamMatch} teamMatch
     * @return {MatchVersus}
     */
    toMatchVersus(teamMatch) {
        const teams = teamMatch.team_match_teams.map((tmt) => {
            let teamUsers = this.team_users
                .where(tu => tu.team_id === tmt.team.id)
                .map(tu => tu.toMatchVersusParticipant());

            return new MatchVersusTeam(
                teamUsers,
                null,
                this.winning_team_id === tmt.team.id,
            );
        });

        const isDraw = this.match.score === 'AS';

        return new MatchVersus(teams, isDraw);
    }
};

export class TeamMatchSchedules extends Collection {
    /**
     * Options of this collection.
     *
     * @return {object}
     */
    options() {
        return {
            model: TeamMatchSchedule,
        };
    }
};
