import {Model, Collection} from './vue-mc/Model';
import {LeagueTeamMatches} from './LeagueTeamMatch';

export class LeagueMatchdayGroup extends Model {
    /**
     * Default attributes that define the "empty" state.
     *
     * @return {object}
     */
    defaults() {
        return {
            league_team_matches: [],
        };
    }

    /**
     * Mutations that should be done to the attributes of this model.
     *
     * @return {object}
     */
    mutations() {
        return {
            league_team_matches: (models) => new LeagueTeamMatches(models),
        };
    }
};

export class LeagueMatchdayGroups extends Collection {
    /**
     * Options of this collection.
     *
     * @return {object}
     */
    options() {
        return {
            model: LeagueMatchdayGroup,
        };
    }

    /**
     * Route configuration.
     *
     * @return {object}
     */
    routes() {
        return {
            fetch: 'league-matchday-groups',
        };
    }
};
