import Enum from './Enum';

/**
 * The team match has been created and can be played.
 */
const PLAYABLE = 0;

/**
 * All the related team match schedules have been played, thus the team
 * match itself has been played.
 */
const PLAYED = 1;

export default class TeamMatchStatus extends Enum {
    /**
     * @returns {Object} Enumeration key-value pairs of the Enum.
     */
    static get enumeration() {
        return {
            PLAYABLE,
            PLAYED,
        };
    };

    static PLAYABLE = PLAYABLE;
    static PLAYED = PLAYED;

    /**
     * @returns {string} Translation key of the Enum.
     */
    static get translationKey() {
        return 'team_match_status';
    }
};
