import {Model, Collection} from './vue-mc/Model';
import {LeagueMatchdayGroups} from './LeagueMatchdayGroup';
import moment from 'moment';

export class LeagueMatchday extends Model {
    /**
     * Default attributes that define the "empty" state.
     *
     * @return {object}
     */
    defaults() {
        return {
            id: null,
            starts_at: null,
            ends_at: null,
            league_matchday_groups: new LeagueMatchdayGroups,
        };
    }

    /**
     * Route configuration.
     *
     * @return {object}
     */
    routes() {
        return {
            fetchLeagueMatchdayGroups: 'league-matchdays/{id}/league-matchday-groups',
        };
    }

    /**
     * Options of this model.
     *
     * @return {object}
     */
    options() {
        return {
            endpoint: 'league-matchdays',
        };
    }

    transformations() {
        return {
            starts_at: moment,
            ends_at: moment,
        };
    }

    /**
     * Fetches league matchdays groups of the league matchday from the api.
     *
     * @return {LeagueMatchdayGroups}
     */
    async fetchLeagueMatchdayGroups() {
        const route = this.getRoute('fetchLeagueMatchdayGroups');

        const options = {
            url: this.getURL(route, this.getRouteParameters()),
        };

        this.league_matchday_groups = new LeagueMatchdayGroups;

        return await this.league_matchday_groups.fetch(options);
    }
};

export class LeagueMatchdays extends Collection {
    /**
     * Options of this collection.
     *
     * @return {object}
     */
    options() {
        return {
            model: LeagueMatchday,
        };
    }
};
