import Enum from './Enum';

/**
 * The team match schedule has been created and can be played.
 */
const PLAYABLE = 0;

/**
 * The team match schedule has been related to a match. This indicates
 * that the schedule has been played.
 */
const PLAYED = 1;

export default class TeamMatchScheduleStatus extends Enum {
    /**
     * @returns {Object} Enumeration key-value pairs of the Enum.
     */
    static get enumeration() {
        return {
            PLAYABLE,
            PLAYED,
        };
    };

    static PLAYABLE = PLAYABLE;
    static PLAYED = PLAYED;

    /**
     * @returns {string} Translation key of the Enum.
     */
    static get translationKey() {
        return 'team_match_schedule_status';
    }
};
