import {Team} from './Team';
import {Model, Collection} from './vue-mc/Model';

export class TeamMatchTeam extends Model {
    /**
     * Default attributes that define the "empty" state.
     *
     * @return {object}
     */
    defaults() {
        return {
            team: null,
        };
    }

    /**
     * Mutations that should be done to the attributes of this model.
     *
     * @return {object}
     */
    mutations() {
        return {
            team: (attributes) => new Team(attributes),
        };
    }
};

export class TeamMatchTeams extends Collection {
    /**
     * Options of this collection.
     *
     * @return {object}
     */
    options() {
        return {
            model: TeamMatchTeam,
        };
    }
};
