import {TeamMatch} from './TeamMatch';
import {Model, Collection} from './vue-mc/Model';

export class LeagueTeamMatch extends Model {
    /**
     * Default attributes that define the "empty" state.
     *
     * @return {object}
     */
    defaults() {
        return {
            team_match: null,
            incorrect_team_match_schedules_count: 0,
        };
    }

    /**
     * Mutations that should be done to the attributes of this model.
     *
     * @return {object}
     */
    mutations() {
        return {
            team_match: (attributes) => new TeamMatch(attributes),
        };
    }
};

export class LeagueTeamMatches extends Collection {
    /**
     * Options of this collection.
     *
     * @return {object}
     */
    options() {
        return {
            model: LeagueTeamMatch,
        };
    }
};
