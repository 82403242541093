import Vue from 'vue';
import {flatten} from 'lodash';
import {TeamUser, TeamUsers} from './TeamUser';
import {TeamMatchTeams} from './TeamMatchTeam';
import {Model, Collection} from './vue-mc/Model';
import {TeamMatchSchedules} from './TeamMatchSchedule';
import {MatchVersus, MatchVersusTeam} from './MatchVersus';
import TeamMatchStatus from '@/library/enums/TeamMatchStatus';
import TeamMatchFormatType from '@/library/enums/TeamMatchFormatType';

export class TeamMatch extends Model {
    /**
     * Default attributes that define the "empty" state.
     *
     * @return {object}
     */
    defaults() {
        return {
            format_type: null,
            team_match_teams: [],
            team_match_schedules: [],
        };
    }

    /**
     * Options of this model.
     *
     * @return {object}
     */
    options() {
        return {
            endpoint: 'team-matches',
        };
    }

    /**
     * Mutations that should be done to the attributes of this model.
     *
     * @return {object}
     */
    mutations() {
        return {
            team_match_teams: (models) => models instanceof TeamMatchTeams ?
                models : new TeamMatchTeams(models),
            team_match_schedules: (models) => models instanceof TeamMatchSchedules ?
                models : new TeamMatchSchedules(models),
        };
    }

    /**
     * Returns the max amount of users per team that may play in this team match.
     *
     * @return {number|undefined}
     */
    get max_per_team() {
        return TeamMatchFormatType.userPerTeamAmountForType(this.format_type);
    }

    /**
     * Transforms the TeamMatch to a MatchVersus instance.
     */
    toMatchVersus(scoreEditable = false) {
        // Check if the team match has ever been played or not. If both teams have
        // 0 score, the team match has never been played. Because even a draw match will
        // result in the score of 1.
        const isNeverPlayed = this.team_match_teams.models.every((tmt) => {
            return tmt.team_match_schedule_team_users_score === 0;
        });

        const teams = this.team_match_teams.map((teamMatchTeam) => {
            return new MatchVersusTeam(
                [teamMatchTeam.team.toMatchVersusParticipant()],
                // If the team match is never played, hide the score.
                isNeverPlayed ? null : teamMatchTeam.team_match_schedule_team_users_score,
                this.winning_team_id === teamMatchTeam.team.id,
            );
        });

        // If the score is editable, even though the TeamMatch is not `PLAYED`,
        // if it doesn't have `winning_team_id`, then we consider it a draw.
        // This is done so the TeamMatch is rendered red, thus the (league) admin
        // knows that its scores need to be verified.
        const isDraw = this.winning_team_id === null
            && (this.status === TeamMatchStatus.PLAYED || scoreEditable);

        return new MatchVersus(teams, isDraw);
    }

    /**
     * Adds the given team user to the first team match schedule that can accept
     * it (if there is one). The team user can not already be playing in
     * this team match.
     *
     * @param {TeamUser} teamUser
     * @param {number} targetTeamId
     * @return {boolean} true if the TeamUser is added succesfully
     */
    addTeamUser(teamUser, targetTeamId = undefined) {
        // Check that the team user belongs to a team.
        const teamId = targetTeamId || teamUser.team_id;

        if (!teamId) return false;

        // Retrieve an array of all the users that have been assigned to each
        // team match schedule.
        let teamUsers = flatten(this.team_match_schedules.map(tms => {
            if (tms.has_been_played) {
                return [];
            }

            return tms.getTeamUsersOfTeam(teamId);
        }));

        // Add the new team user to the array of team users and sort them by
        // handicap.
        teamUsers.push(teamUser);

        let ordered = teamUsers.sort((a, b) => {
            if (a === b) {
                return 0;
            }

            if (!a || !a.user) {
                return 1;
            }

            if (!b || !b.user) {
                return -1;
            }

            return a.user.handicap - b.user.handicap;
        });

        // Make sure there are enough team users to fill all the team match
        // schedules.
        ordered = [
            ...ordered,
            ...new Array(this.team_match_schedules.length * 2).fill({}),
        ];

        // Fill the team users of each team match schedule with the correct
        // amount.
        this.team_match_schedules.each((tms) => {
            if (tms.has_been_played) {
                return;
            }

            const teamUsersForSchedule = ordered.splice(0, tms.max_per_team);

            tms.team_users = new TeamUsers(teamUsersForSchedule);
        });

        return true;
    }
};

export class TeamMatches extends Collection {
    /**
     * Options of this collection.
     *
     * @return {object}
     */
    options() {
        return {
            model: TeamMatch,
        };
    }
};
