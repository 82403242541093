import Enum from './Enum';

const SINGLE = 1;

const FOURBBB = 2;

export default class MatchType extends Enum {
    /**
     * @returns {Object} Enumeration key-value pairs of the Enum.
     */
    static get enumeration() {
        return {
            SINGLE,
            FOURBBB,
        };
    };

    static SINGLE = SINGLE;
    static FOURBBB = FOURBBB;

    /**
     * @returns {string} Translation key of the Enum.
     */
    static get translationKey() {
        return 'match_type';
    }

    /**
     * Returns the amount of users that each team should provide when playing
     * the given match type.
     *
     * @param {number} type
     * @returns {number}
     */
    static userPerTeamAmountForType(type) {
        switch (type) {
            case SINGLE:
                return 1;

            case FOURBBB:
                return 2;
        }
    }
};
